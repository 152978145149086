import React from 'react';
import DynamicTable from './DynamicTable/DynamicTable';
import matchingFlyer from '../img/2024/matching2024.png';



export const Matching =() => {
  return (
    <div>
            <h2>合同説明会日程</h2>
            <img className='matchingFlyer' src={matchingFlyer} alt="合同企業説明会チラシ" />
            {/* <p>再就職セミナーは日程を終了いたしました。</p> */}
            <h3>参加企業一覧</h3>
            <DynamicTable />
            <p className='upDate'>2024/9/2更新</p>
            <p className='atentionText'>参加事業所はチラシ作成時のものです。変更となる場合もございますので予めご了承ください。</p>
    </div>
  );
};
