 const companies = {
    aomori: {
      date: '10月23日（水）',
      companies: [
        '株式会社セントラルパートナーズ',
        '岩手県北自動車 株式会社',
        '社会福祉法人 諏訪ノ森会',
        '有限会社 青森丸栄商事',
        '株式会社 あおもりコープフーズ',
        '株式会社 しんとう計測',
        '株式会社 ニチイ学館',
        '成長タクシー 株式会社',
        '東京太陽株式会社 青森タクシー営業所',
        '十和田観光電鉄 株式会社',
        '社会福祉法人 ゆきわり会',
        '株式会社東奥日報サービス 東奥日報人材センターアウル',
        '社会福祉法人 青空会',
        '社会福祉法人心和会　障害者支援施設金浜療護園',
        '有限会社 東北ビル綜合管理',
        'アクサ生命保険 株式会社 青森営業所',
        '株式会社 城ヶ倉観光',
        '青森設備工業 株式会社',
        '津軽警備保障 株式会社',
        '社会福祉法人 青森県すこやか福祉事業団',
        '株式会社 トスネット 北東北　青森営業所',
        '株式会社 NTTデータ・スマートソーシング',
        '有限会社 丸髙 髙橋蒲鉾店'
      ],
    },
    hachinohe: {
      date: '10月25日（金）',
      companies: [
        '有限会社 東北ファーム',
        '株式会社 小坂工務店　',
        '有限会社 興産タクシー',
        '株式会社 エンジョブ',
        '太平ビルサービス 株式会社 八戸支店',
        '岩手県北自動車 株式会社',
        '東北医療福祉事業協同組合(SGグループ)',
        '株式会社 トーショウビルサービス 八戸営業所',
        '株式会社 ニチイ学館',
        '株式会社 共同物流サービス',
        '社会福祉法人 吉幸会 特別養護老人ホーム　ひだまり',
        '十和田観光電鉄 株式会社',
        '株式会社 三沢警備保障',
        '株式会社 ゆとり',
        '株式会社 トスネット北東北 八戸営業所',
        '青森みちのく警備保障 株式会社 八戸営業所',
        '東和警備 株式会社',
        '株式会社 ビリーブケアサポート',
        '株式会社 カネマツ （ライジンググループ）',
        '社会福祉法人 八戸市社会福祉事業団',
        '株式会社 オーエスアドバンス',
        '三浦建設工業 株式会社',
        '共同エンジニアリング株式会社'
      ],
    },
  };
  
  export default companies;