import React from 'react';

const TableHeader = ({ dates }) => {
  return (
    <thead>
      <tr>
        <th id="aomori">青森会場　{dates.aomori}</th>
        <th id="hachinohe">八戸会場　{dates.hachinohe}</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
