import React from 'react';

const TableRow = ({ aomoriCompany, hachinoheCompany, index }) => {
  return (
    <tr key={index}>
      <td headers="aomori">{aomoriCompany}</td>
      <td headers="hachinohe">{hachinoheCompany}</td>
    </tr>
  );
};

export default TableRow;
