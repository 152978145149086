import React from 'react';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import Companies from './Companies';

const DynamicTable = () => {
  return (
    <table border="1">
      <TableHeader dates={{ aomori: Companies.aomori.date, hachinohe: Companies.hachinohe.date }} />
      <tbody>
        {Companies.aomori.companies.map((company, index) => (
          <TableRow
            key={index}
            aomoriCompany={company}
            hachinoheCompany={Companies.hachinohe.companies[index]}
          />
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;

